<template>
  <navigation index="sysRepairManage" ref="navigations">
    <div style="height: 100%; width: calc(100% - 0px)">
      <div
        style="
          background-color: #01a1ff;
          border-radius: 1px;
          height: 60px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: calc(100% - 10px);
        "
      >
        <div style="display: flex; align-items: center; gap: 15px">
          <el-button @click="AdminLogin">
          {{ tools.GetLanguageValue("web.登录") }}</el-button
        >
          <!-- <el-select
            v-model="userSeletvalue"
            filterable
            remote
            reserve-keyword
            :placeholder="tools.GetLanguageValue('web.请输入客户信息')"
            :remote-method="remoteMethod"
            :loading="userListloading"
            @change="userSelected"
            style="margin-left: 10px"
            @click="UserSeletValueWindow = !UserSeletValueWindow"
          >
            <el-option
              style="width: 330px"
              v-for="item in userListoptions"
              :key="item.Id"
              :label="item.Name"
              :value="item"
            >
              <div style="display: flex; justify-content: space-between">
                <div>
                  {{ item.Name }}
                </div>

                <div>
                  {{ item.DocNumber }}
                </div>
              </div>
              <span> {{ item.Name + item.DocNumber }}</span>
            </el-option>

            <template #prefix>
              <el-icon><Avatar /></el-icon>
            </template>
          </el-select> -->
          <!-- <el-tooltip
            class="box-item"
            effect="dark"
            :content="tools.GetLanguageValue('web.添加新客户')"
            placement="top-start"
          >
            <span>
              <el-icon :size="22" color="#fff" @click.stop="clearUserForm"
                ><CirclePlus
              /></el-icon>
            </span>
          </el-tooltip> -->
        </div>
<!-- 
        <div
          v-if="!repairForm.IsCreate"
          style="
            display: flex;
            justify-content: right;
            align-items: center;
            gap: 10px;
            margin-right: 10px;
          "
        >
          <el-button size="large">
            <el-checkbox
              v-model="settingModel.DotPrintDrawer"
              @change="ChangeDotPrintDrawer"
            >
              {{ tools.GetLanguageValue("web.不打印操作人") }}
            </el-checkbox>
          </el-button>

          <el-button size="large" style="color: #fff">
            <el-checkbox
              v-model="settingModel.DotPrintCounterfoil"
              @change="ChangeDotPrintCounterfoil"
            >
              {{ tools.GetLanguageValue("web.不打印存根") }}
            </el-checkbox>
          </el-button>

          <el-button size="large" @click="printXiaoPiao">
            {{ tools.GetLanguageValue("web.打印小票") }}</el-button
          >
          <el-button size="large" @click="printXiaoPiaoAfour">
            {{ tools.GetLanguageValue("web.打印小票A4") }}
          </el-button>
          <el-button
            size="large"
            @click="UpdatePrintInfo"
            style="margin-right: 15px"
          >
            {{ tools.GetLanguageValue("web.打印保修") }}</el-button
          >

          <el-popover
            placement="top-start"
            :title="tools.GetLanguageValue('web.快捷键')"
            :width="300"
            trigger="hover"
          >
            <template #reference>
      
              <el-icon class="el-icon--right" color="#fff"
          style="font-size: 18pt; margin-right:  5px"> <InfoFilled /></el-icon>
            </template>

            <P>alt + Q {{ tools.GetLanguageValue("web.打印小票") }}</P>
            <P>alt + W {{ tools.GetLanguageValue("web.打印小票A4") }}</P>
            <P>alt + E {{ tools.GetLanguageValue("web.打印保修") }}</P>
            <P>alt + C {{ tools.GetLanguageValue("web.新增维修") }}</P>
            <P>alt + D {{ tools.GetLanguageValue("web.添加新客户") }}</P>
            <P>alt + S {{ tools.GetLanguageValue("web.保存表单") }}</P>
            <P>
              {{ tools.GetLanguageValue("web.品牌和属性红色的为临时数据") }}</P
            >
          </el-popover>
        </div> -->
      </div>
      <el-scrollbar
        style="
          margin-top: 10px;
          width: calc(100% - 1px);
          height: calc(100% - 80px);
        "
      >
        <div
          style="
            border: 1px solid #eee;
            box-shadow: 2px 2px 20px 2px #eee;
            width: calc(100% - 20px);
            margin: 0 auto;
          "
        >
          <!-- //输入客户信息 -->
          <el-form :model="userForm">
            <div style="display: flex; margin: 3px">
              <div
                style="
                  display: flex;
                  width: calc(100% - 320px);
                  flex-wrap: wrap;
                  gap: 2px 5px;
                "
              >
                <el-input
                  class="userInfoInput"
                  :placeholder="tools.GetLanguageValue('web.姓名')"
                  v-model="userForm.Name"
                >
                  <template #prefix>
                    <el-icon><Avatar /></el-icon>
                  </template>
                </el-input>
                <el-input
                  class="userInfoInput"
                  :placeholder="tools.GetLanguageValue('web.电话')"
                  v-model="userForm.PhoneNumber"
                >
                  <template #prefix>
                    <el-icon><PhoneFilled /></el-icon>
                  </template>
                </el-input>

                <el-input
                  class="userInfoInput"
                  :placeholder="tools.GetLanguageValue('web.电子邮件')"
                  v-model="userForm.Email"
                >
                  <template #prefix>
                    <el-icon><Message /></el-icon>
                  </template>
                </el-input>

                <el-input
                  :placeholder="tools.GetLanguageValue('web.地址')"
                  class="userInfoInput"
                  v-model="userForm.Address"
                >
                  <template #prefix>
                    <el-icon><Location /></el-icon>
                  </template>
                </el-input>

                <el-select
                  v-model="userForm.Level"
                  class="userInfoInput"
                  style="width: 23%"
                >
                  <template #prefix>
                    <el-icon><Star /></el-icon>
                  </template>
                  <el-option
                    v-for="item in clientLevelOptions"
                    :key="item.Value"
                    :label="item.Value"
                    :value="item.Key"
                  ></el-option>
                </el-select>

                <el-select
                  v-model="userForm.DocType"
                  class="userInfoInput"
                  style="width: 23%"
                  :placeholder="tools.GetLanguageValue('web.请选择证件类型')"
                >
                  <template #prefix>
                    <el-icon><Management /></el-icon>
                  </template>
                  <el-option
                    v-for="item in docTypeOptions"
                    :key="item.Key"
                    :label="item.Value"
                    :value="item.Key"
                  ></el-option>
                </el-select>

                <el-input
                  :placeholder="tools.GetLanguageValue('web.证件号')"
                  class="userInfoInput"
                  v-model="userForm.DocNumber"
                >
                  <template #prefix>
                    <el-icon><Postcard /></el-icon>
                  </template>
                </el-input>
              </div>
              <div
                style="
                  width: 320px;
                  height: 60px;
                  line-height: 60px;
                  text-align: center;
                "
              >
             
              </div>
            </div>
          </el-form>
        </div>

        <el-row
          style="
            width: calc(100% - 15px);
            margin: 0 auto;
            background-color: #fff;
            border: 1px solid #eee;
            margin-top: 15px;
            box-shadow: 2px 2px 20px 2px #eee;
          "
          v-for="(item, index) in repairForm.RepairMobileInfos"
          :key="index"
          :gutter="10"
        >
          <el-col :span="24">
            <div style="min-height: 350px" :id="item.Id">
            
              <div style="display: flex; width: 100%">
                <div style="width: 60%; padding: 0px 15px">
                  <el-divider content-position="left" v-if="!item.IsReworking">
               
                   </el-divider
                  >
                  <el-divider content-position="left">
                    <span  v-if="!item.IsReworking"> {{ tools.GetLanguageValue("web.维修") }}</span>
                    <span  v-if="item.IsReworking" style="font-size: 20pt; color: red">
                      {{ tools.GetLanguageValue("web.返修") }}</span
                    >
                    <span  v-if="item.IsDelete" style="font-size: 20pt; color: red">
                        ( {{ tools.GetLanguageValue("web.删除") }})</span
                    >
                    </el-divider
                  >

                 


                  <el-form :model="item" style="margin: 5px">
                    <div style="display: flex; margin: 5px 0px">
                      <el-select v-model="item.Repairstatus" size="default">
                        <el-option
                          v-for="item in repairstatusOptions"
                          :key="item.Key"
                          :value="item.Key"
                          :label="item.Value"
                        >
                          {{ item.Value }}
                        </el-option>
                      </el-select>
                      
                    </div>
                    <div>
                      <div style="display: flex; gap: 8px">
                        <el-select :disabled="true"
                          style="width: 50%"
                          v-model="item.BrandObj"
                          filterable
                          allow-create
                          default-first-option
                          :placeholder="tools.GetLanguageValue('web.牌子')"
                        >
                          <el-option
                            label="请选择"
                            value="00000000-0000-0000-0000-000000000000"
                          >
                          </el-option>
                          <el-option
                            v-for="item in brandOptions"
                            :key="item.Id"
                            :label="item.Name"
                            :value="item.Id"
                          >
                            <span v-if="item.IsTemp" style="color: red">
                              {{ item.Name }}
                            </span>
                            <span v-else>
                              {{ item.Name }}
                            </span>
                          </el-option>
                          <template #prefix>
                            <el-icon><Apple /></el-icon>
                          </template>
                        </el-select>

                        <el-select :disabled="true"
                          style="width: 50%"
                          v-model="item.ModelObj"
                          filterable
                          allow-create
                          default-first-option
                          :placeholder="tools.GetLanguageValue('web.型号')"
                        >
                          <el-option
                            label="请选择"
                            value="00000000-0000-0000-0000-000000000000"
                          >
                          </el-option>
                          <el-option
                            v-for="item in ProductModels"
                            :key="item.Id"
                            :label="item.Name"
                            :value="item.Id"
                          >
                            <span v-if="item.IsTemp" style="color: red">
                              {{ item.Name }}
                            </span>
                            <span v-else>
                              {{ item.Name }}
                            </span>
                          </el-option>
                          <template #prefix>
                            <el-icon><Cpu /></el-icon>
                          </template>
                        </el-select>
                      </div>
                    </div>
                    <div
                      style="
                        margin: 5px 0px;
                        display: flex;
                        gap: 4;
                        justify-content: space-between;
                      "
                    >
                      <el-input v-model="item.ImelNumber" style="width: 250px">
                        <template #prefix>
                          <span style="margin: 0px 5px">IMEI </span>
                        </template>
                      </el-input>

                      <el-select
                        style="width: calc(100% - 300px)"
                        v-model="item.ModelProperties"
                        filterable :disabled="true"
                        multiple
                        :placeholder="tools.GetLanguageValue('web.属性')"
                      >
                        <el-option-group
                          v-for="group in attributeOptions"
                          :key="group.Id"
                          :label="group.Name"
                        >
                          <el-option
                            v-for="item in group.ProductAttribute"
                            :key="item.Id"
                            :label="item.Name"
                            :value="item.Id"
                          />
                        </el-option-group>
                      </el-select>

                 
                    </div>
                    <div>
                      <el-tabs v-model="item.ActiveTab">
                        <el-tab-pane name="server">
                          <template #label>
                            <span class="custom-tabs-label">
                              <el-icon><calendar /></el-icon>
                              <span>
                                {{
                                  tools.GetLanguageValue("web.维修问题/服务")
                                }}</span
                              >
                           
                            </span>
                          </template>

                          <div>
                            <el-button
                              type="primary"
                             
                              :key="server.Id"
                              size="small"
                              v-for="server in serverQuestionOptions"
                            >
                              {{ server.Name }}
                            </el-button>
                          </div>
                        </el-tab-pane>
                        <el-tab-pane
                          :label="tools.GetLanguageValue('web.配件')"
                          name="fittings"
                        >
                          <template #label>
                            <span class="custom-tabs-label">
                              <el-icon><calendar /></el-icon>
                              <span>{{
                                tools.GetLanguageValue("web.配件")
                              }}</span>
                            
                            </span>
                          </template>
                          <div>
                            <el-button
                              type="primary"
                             
                              :key="accessories.Id"
                              size="small"
                              style="margin: 3px 3px 0px 0px"
                              v-for="accessories in accessoriesOptions"
                            >
                              {{ accessories.Name }}
                            </el-button>
                          </div>
                        </el-tab-pane>
                      </el-tabs>
                    </div>

                    <div
                      style="
                        margin: 5px 0px;
                        margin-top: 10px;
                        display: flex;
                        gap: 3px 8px;
                        align-items: center;
                        flex-wrap: wrap;
                      "
                    >
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="tools.GetLanguageValue('web.请选择密码类型')"
                        placement="bottom-end"
                      >
                        <span>
                          <el-select
                            prefix="Lock"
                            style="width: 140px"
                            v-model="item.PasswordType"
                            :placeholder="
                              tools.GetLanguageValue('web.请选择密码类型')
                            "
                          >
                            <template #prefix>
                              <el-icon style=""><Lock /></el-icon>
                            </template>

                            <el-option
                              v-for="item in passwordTypeOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </span>
                      </el-tooltip>

                      <div v-if="item.PasswordType == 1">
                        <el-input
                          style="min-width: 100px"
                          v-model="item.Password"
                          :placeholder="
                            tools.GetLanguageValue('web.输入解锁密码')
                          "
                        ></el-input>
                      </div>
                      <div v-if="item.PasswordType == 2">
                        <BoxGridPassword
                          :modelId="index"
                          v-model="item.Password"
                        >
                        </BoxGridPassword>
                      </div>

               
                    </div>
                    <div>
                      <el-input
                        v-model="item.Comment"
                        autosize
                        :placeholder="tools.GetLanguageValue('web.请填写备注')"
                      >
                        <template #prefix>
                          <el-icon><Document /></el-icon>
                        </template>
                      </el-input>
                    </div>
                    <div style="margin: 5px 0px">
                      <el-input
                        v-model="item.PrivateComment"
                        autosize
                        :placeholder="
                          tools.GetLanguageValue('web.请填写备注.不打印')
                        "
                      >
                        <template #prefix>
                          <el-icon><Warning /></el-icon>
                        </template>
                      </el-input>
                    </div>
                    <div style="display: flex; gap: 5px">
                        <el-tooltip
                          class="box-item"
                          effect="dark"
                          :content="tools.GetLanguageValue('web.维修时间')"
                          placement="bottom-end"
                        >
                          <span>
                            <el-date-picker
                              style="width: 185px"     value-format="YYYY/MM/DD HH:mm:ss"
                              v-model="item.RepairStartTime"
                              type="datetime"
                              :clear-icon="Close"
                              :prefix-icon="Refresh"
                              :placeholder="
                                tools.GetLanguageValue('web.维修时间')
                              "
                            />
                          </span>
                        </el-tooltip>

                        <div>
                          <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="tools.GetLanguageValue('web.保修时间')"
                            placement="bottom-end"
                          >
                            <span>
                              <el-date-picker
                                style="width: 185px"
                                v-model="item.WarrantyStartTimeFormat"
                                :clear-icon="Close"
                                :prefix-icon="CircleCheck"
                                type="datetime"
                                :placeholder="
                                  tools.GetLanguageValue('web.保修时间')
                                "
                              />
                            </span>
                          </el-tooltip>
                        </div>
                      </div>

                    <div>
                      <el-form-item
                        label="隐藏的前端手机Id"
                        prop="hiddenField"
                        v-if="false"
                      >
                        <el-input v-model="item.FrontMobileId"></el-input>
                      </el-form-item>
                    </div>
                  </el-form>
                </div>
                <div style="width: 50%; height: 350px; padding: 0px 5px">
                  <el-divider content-position="left">
                    {{ tools.GetLanguageValue("web.金额") }}</el-divider
                  >
                  <el-row style="width: 100%" gutter="10">
                    <el-col :span="12">
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="tools.GetLanguageValue('web.预算')"
                        placement="top-start"
                      >
                        <el-input
                          v-model="item.Budget"
                          @input="
                            (v) => {
                              item.Budget =
                                v
                                  .replace(/[^\d.]/g, '')
                                  .replace(/^0(\d)/, '$1')
                                  .replace(/^\./, '0.')
                                  .match(/^\d*(\.?\d{0,4})/g)[0] || '';
                            }
                          "
                          :placeholder="
                            tools.GetLanguageValue('web.请输入预算')
                          "
                        >
                          <template #prefix>
                            <svg
                              t="1703631359330"
                              style="width: 18px; height: 18px"
                              class="icon"
                              viewBox="0 0 1024 1024"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              p-id="6903"
                              width="200"
                              height="200"
                            >
                              <path
                                d="M695.128195 314.811826a22.378321 22.378321 0 1 0 0-44.756642h-100.101427V182.511192a22.378321 22.378321 0 1 0-44.756642 0v87.543992h-70.52368V182.511192a22.378321 22.378321 0 1 0-44.756642 0v87.572764c-70.143249 0.597821-127.025744 57.815991-127.025744 128.096706v7.260167c0 68.880472 54.641466 125.2131 122.850588 127.994406 1.349093 0.326084 2.742943 0.527489 4.175156 0.59782v174.573283h-104.647423a22.378321 22.378321 0 1 0 0 44.756642h104.647423v87.543991c0 0.22698 0.028772 0.447566 0.035166 0.67135-149.52235-35.223477-261.158203-169.749156-261.158203-329.872436a22.378321 22.378321 0 1 0-44.756642 0c0 211.532678 172.095682 383.62836 383.62836 383.62836a22.378321 22.378321 0 1 0 0-44.756642c-11.630333 0-23.126396-0.591427-34.462615-1.739115a22.307989 22.307989 0 0 0 1.473773-7.934714v-87.543991h70.52368v87.543991a22.378321 22.378321 0 1 0 44.756642 0v-88.202553c68.03649-2.966726 122.479748-59.229022 122.479747-127.984815v-7.260167c0-68.755793-54.440061-125.018089-122.479747-127.984814V314.811826h100.09823z m-342.404296 90.625806v-7.260166c0-45.600624 36.805944-82.74544 82.269102-83.340065v173.943493c-45.463158-0.591427-82.269102-37.73944-82.269102-83.343262z m127.025744 303.165509v-174.518935h70.52368v174.518935h-70.52368z m193.000231-91.143705v7.260167c0 44.072505-34.376298 80.242265-77.723106 83.161038V534.298398c43.346808 2.915576 77.723106 39.088533 77.723106 83.161038z m-122.476551-128.64977h-70.52368V314.811826h70.52368v173.99784z"
                                fill="#515151"
                                p-id="6904"
                              ></path>
                              <path
                                d="M512.738485 44.958047c-257.366676 0-466.747838 209.381162-466.747838 466.747838s209.381162 466.747838 466.747838 466.747838 466.747838-209.381162 466.747838-466.747838-209.381162-466.747838-466.747838-466.747838z m0 888.739034c-232.686585 0-421.991196-189.304611-421.991196-421.991196s189.304611-421.991196 421.991196-421.991196 421.991196 189.304611 421.991196 421.991196-189.304611 421.991196-421.991196 421.991196z"
                                fill="#515151"
                                p-id="6905"
                              ></path>
                              <path
                                d="M153.476919 467.422385a22.384715 22.384715 0 0 0 26.083532-17.925036 336.889638 336.889638 0 0 1 22.065024-72.339521 22.378321 22.378321 0 0 0-41.073809-17.787568 381.742187 381.742187 0 0 0-24.999782 81.968593 22.378321 22.378321 0 0 0 17.925035 26.083532z"
                                fill="#515151"
                                p-id="6906"
                              ></path>
                            </svg>
                          </template>
                        </el-input>
                      </el-tooltip>
                    </el-col>
                    <el-col :span="12">
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="tools.GetLanguageValue('web.定金')"
                        placement="top-start"
                      >
                        <el-input
                          v-model="item.Downpayment"
                          :placeholder="
                            tools.GetLanguageValue('web.请输入定金')
                          "
                          @input="
                            (v) => {
                              item.Downpayment =
                                v
                                  .replace(/[^\d.]/g, '')
                                  .replace(/^0(\d)/, '$1')
                                  .replace(/^\./, '0.')
                                  .match(/^\d*(\.?\d{0,4})/g)[0] || '';
                            }
                          "
                        >
                          <template #prefix>
                            <svg
                              t="1703631430717"
                              style="width: 18px; height: 18px"
                              class="icon"
                              viewBox="0 0 1024 1024"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              p-id="9961"
                              width="200"
                              height="200"
                            >
                              <path
                                d="M921.529187 352.519776C878.16454 242.165412 794.426371 155.310252 685.74409 107.945501c-108.682281-47.357588-229.316779-49.559742-339.671143-6.210445-98.722442 38.794561-178.632423 109.902062-228.293473 202.27182l0-153.383366c0-11.04659-8.952906-19.999496-19.999496-19.999496-11.04659 0-19.999496 8.952906-19.999496 19.999496l0 217.020732c0 11.04659 8.952906 19.999496 19.999496 19.999496l217.028918 0c11.04659 0 19.999496-8.952906 19.999496-19.999496 0-11.04659-8.952906-19.999496-19.999496-19.999496L140.806933 347.644745c43.630706-96.036263 121.485889-170.011068 219.890083-208.68488 100.401688-39.451524 210.177883-37.435611 309.063031 5.655813 98.893334 43.091424 175.085644 122.126478 214.544331 222.528166 39.451524 100.408851 37.443797 210.16151-5.655813 309.055868-43.091424 98.901521-122.126478 175.092807-222.528166 214.544331-100.408851 39.466873-210.169697 37.45096-309.063031-5.64865-72.050989-31.397081-132.869147-82.542948-175.866427-147.899468-6.077416-9.241478-18.491143-11.780301-27.701922-5.718235-9.226129 6.077416-11.788487 18.475793-5.718235 27.701922 47.278793 71.855538 114.119107 128.071887 193.303564 162.585959 56.864102 24.780383 117.001761 37.201274 177.218214 37.201274 54.888098 0 109.838617-10.320043 162.452929-30.990828C781.098831 884.611369 867.953991 800.8732 915.318742 692.182732 962.683493 583.492264 964.894857 462.865953 921.529187 352.519776z"
                                fill="#515151"
                                p-id="9962"
                              ></path>
                              <path
                                d="M424.708911 727.178781c0.008186 0 0.023536 0 0.031722 0 5.711072 0 11.156084-2.984984 14.944364-7.250124l312.328401-351.929328c7.343245-8.257058 6.593162-20.892843-1.655709-28.228925-8.257058-7.351432-20.905122-6.593162-28.233018 1.664919L424.787706 675.975609 308.081681 543.851429c-7.30436-8.280594-19.952424-9.002025-28.233018-1.682315-8.273431 7.312546-9.0624 20.087501-1.749854 28.368094l131.650389 149.34233C413.538501 724.168214 418.983513 727.178781 424.708911 727.178781z"
                                fill="#515151"
                                p-id="9963"
                              ></path>
                            </svg>
                          </template>
                        </el-input>
                      </el-tooltip>
                    </el-col>
                  </el-row>

                  <el-row style="width: 100%; margin-top: 3px" gutter="10">
                    <el-col :span="14">
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="tools.GetLanguageValue('web.名称')"
                        placement="top-start"
                      >
                        <el-input
                         
                          v-model="item.AddCustomModel.Name"
                          :placeholder="
                            tools.GetLanguageValue('web.请输入名称')
                          "
                        >
                          <template #prefix>
                            <el-icon><Tickets /></el-icon>
                          </template>
                        </el-input>
                      </el-tooltip>
                    </el-col>
                    <el-col :span="4">
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="tools.GetLanguageValue('web.数量')"
                        placement="top-start"
                      >
                        <el-input
                          v-model="item.AddCustomModel.Number"
                          :placeholder="
                            tools.GetLanguageValue('web.请输入数量')
                          "
                          @input="
                            (v) => {
                              item.AddCustomModel.Number =
                                v
                                  .replace(/[^\d.]/g, '')
                                  .replace(/^0(\d)/, '$1')
                                  .replace(/^\./, '0.')
                                  .match(/^\d*(\.?\d{0,4})/g)[0] || '';
                            }
                          "
                       
                        >
                        </el-input>
                      </el-tooltip>
                    </el-col>
                    <el-col :span="6">
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="tools.GetLanguageValue('web.单价')"
                        placement="top-start"
                      >
                        <el-input
                          v-model="item.AddCustomModel.Price"
                          :placeholder="
                            tools.GetLanguageValue('web.请输入单价')
                          "
                           
                        >
                        </el-input>
                      </el-tooltip>
                    </el-col>

                    <el-col :span="2">
                     </el-col>
                  </el-row>

                  <el-row style="width: 100%; margin-top: 3px">
                    <el-table
                      @cell-dblclick="editRepairItemData"
                      border
                      :data="item.RepairItems"
                      style="width: 100%"
                      height="220"
                    >
                      <el-table-column
                        prop="Name"
                        :label="tools.GetLanguageValue('web.名字')"
                      >
                        <template #default="scope">
                       
                          <span >{{ scope.row.Name }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="Number"
                        :label="tools.GetLanguageValue('web.数量')"
                        width="80"
                      >
                        <template #default="scope">
                 
                          <span >{{ scope.row.Number }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="Price"
                        :label="tools.GetLanguageValue('web.单价')"
                        width="80"
                      >
                        <template #default="scope"> 
                          <span >{{ scope.row.PriceFormat }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="TotalFormat"
                        :label="tools.GetLanguageValue('web.金额')"
                        width="100px"
                      ></el-table-column>
                     
                    </el-table>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 60px;
                      "
                    ></div>
                  </el-row>
                </div>
              </div>
            </div>
          </el-col>
          <div
            style="
              margin: 10px;
              display: flex;
              gap: 5px 5px;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
              width: 100%;

              padding: 0px 15px;
            "
          >
         
            <div
              style="
                font-size: 18pt;
                width: 100%;
                display: flex;
                justify-content: flex-end;
              "
            >
              {{ tools.GetLanguageValue("web.合计金额") }} :
              <label style="color: red; margin-right: 10px"
                >€{{ item.TotalPrice }}</label
              >
            </div>
          </div>
        </el-row>
        <br />
        <br />
        <br />
        <br />
        <br />

        <!-- <el-button
          type="primary"
          style="
            width: 250px;
            height: 50px;
            position: fixed;
            bottom: 5px;
            font-size: 15pt;
            right: 40px;
            z-index: 1000000;
          "
          @click="insertRepairInfo"
        >
          {{ tools.GetLanguageValue("web.保存") }}</el-button
        > -->
      </el-scrollbar>

      <!-- 添加 -->
      <el-dialog
        v-model="ProductServiceWindow"
        :title="tools.GetLanguageValue('web.新增服务')"
        width="60%"
        :show-close="false"
      >
        <el-form
          ref="form"
          :model="ProductServerOpt"
          label-width="80px"
          style="margin-top: 10px"
        >
          <el-row>
            <el-col :span="10">
              <el-form-item :label="tools.GetLanguageValue('web.服务名称')">
                <el-input
                  v-model="ProductServerOpt.Name"
                  :placeholder="tools.GetLanguageValue('web.请输入服务名称')"
                  @focus="InputSelect"
                ></el-input>
              </el-form-item>

              <el-form-item :label="tools.GetLanguageValue('web.助记码')">
                <el-input
                  v-model="ProductServerOpt.MnemonicCode"
                  :placeholder="tools.GetLanguageValue('web.请输入助记码')"
                  @focus="InputSelect"
                ></el-input>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.编号')">
                <el-input
                  v-model="ProductServerOpt.Code"
                  :placeholder="tools.GetLanguageValue('web.请输入编号')"
                  @focus="InputSelect"
                ></el-input>
              </el-form-item>

              <el-form-item :label="tools.GetLanguageValue('web.单价')">
                <el-input-number
                  @focus="InputSelect"
                  v-model="ProductServerOpt.Price"
                ></el-input-number>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.成本价')">
                <el-input-number
                  @focus="InputSelect"
                  v-model="ProductServerOpt.Cost"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="4"> </el-col>
            <el-col :span="10">
              <el-form-item :label="tools.GetLanguageValue('web.原价')">
                <el-input-number
                  @focus="InputSelect"
                  v-model="ProductServerOpt.OldPrice"
                ></el-input-number>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.排序')">
                <el-input-number
                  @focus="InputSelect"
                  v-model="ProductServerOpt.Sort"
                ></el-input-number>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.是否含税')">
                <el-switch
                  v-model="ProductServerOpt.IncludeTax"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.税率')">
                <el-input-number
                  @focus="InputSelect"
                  v-model="ProductServerOpt.TaxRate"
                  :max="100"
                  :min="0"
                ></el-input-number>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.备注')">
                <el-input
                  :placeholder="tools.GetLanguageValue('web.请输入备注')"
                  v-model="ProductServerOpt.Description"
                  @focus="InputSelect"
                ></el-input>
              </el-form-item>
              <!-- <el-row>
                <el-col :span="12">
                  <el-form-item label="封面图">
                    <PictureCover
                      v-model="ProductServerOpt.Images"
                      v-model:ImageFormet="ProductServerOpt.ImagesFormet"
                      v-model:ImageModel="ProductServerOpt"
                    />
                  </el-form-item>
                </el-col>
              </el-row> -->
            </el-col>
          </el-row>
        </el-form>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="ProductServiceWindow = false">
              {{ tools.GetLanguageValue("web.关闭") }}</el-button
            >
            <el-button @click="AddProductService" type="primary">
              {{ tools.GetLanguageValue("web.保存") }}</el-button
            >
          </span>
        </template>
      </el-dialog>

      <!-- 添加 -->
      <el-dialog
        v-model="OpenAttachmentWindow"
        :title="tools.GetLanguageValue('web.编辑配件')"
        width="700px"
        :show-close="false"
      >
        <el-form
          ref="form"
          :model="AttachmentOpt"
          label-width="80px"
          style="margin-top: 15px"
        >
          <el-form-item :label="tools.GetLanguageValue('web.配件名称')">
            <el-input
              :placeholder="tools.GetLanguageValue('web.请输入配件名称')"
              v-model="AttachmentOpt.Name"
              @focus="InputSelect($event)"
            ></el-input>
          </el-form-item>
          <el-form-item :label="tools.GetLanguageValue('web.助记码')">
            <el-input
              v-model="AttachmentOpt.MnemonicCode"
              :placeholder="tools.GetLanguageValue('web.请输入助记码')"
              @focus="InputSelect($event)"
            ></el-input>
          </el-form-item>

          <el-form-item :label="tools.GetLanguageValue('web.配件价格')">
            <el-input-number
              @focus="InputSelect($event)"
              v-model="AttachmentOpt.Price"
              :min="0"
              :max="100000"
            ></el-input-number>
          </el-form-item>
          <el-form-item :label="tools.GetLanguageValue('web.配件税率')">
            <el-input-number
              @focus="InputSelect($event)"
              v-model="AttachmentOpt.TaxRate"
              :min="0"
              :max="100"
            ></el-input-number>
          </el-form-item>
          <el-form-item :label="tools.GetLanguageValue('web.是否含税')">
            <el-switch
              v-model="AttachmentOpt.IncludeTax"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
          <el-form-item :label="tools.GetLanguageValue('web.备注')">
            <el-input
              :placeholder="tools.GetLanguageValue('web.请输入备注')"
              v-model="AttachmentOpt.Description"
              @focus="InputSelect($event)"
            ></el-input>
          </el-form-item>

          <el-form-item :label="tools.GetLanguageValue('web.封面图')">
            <el-upload
              class="avatar-uploader"
              :action="UploadPictureImageUrl"
              :show-file-list="false"
              :on-success="UploadSuccess"
              :before-upload="UploadSuccess"
              :headers="headersImage"
            >
              <img
                v-if="AttachmentOpt.ImagesFormat"
                :src="AttachmentOpt.ImagesFormat"
                class="avatar"
              />
              <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
            </el-upload>
          </el-form-item>
        </el-form>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="OpenAttachmentWindow = false">
              {{ tools.GetLanguageValue("web.关闭") }}
            </el-button>
            <el-button type="primary" @click="AddAttachment">
              {{ tools.GetLanguageValue("web.保存") }}</el-button
            >
          </span>
        </template>
      </el-dialog>

      <!-- 新增属性小项 -->
      <el-dialog
        v-model="AttributeGroupItemWindow"
        :title="tools.GetLanguageValue('web.新增属性')"
        width="800px"
        :draggable="true"
        :show-close="false"
      >
        <el-form
          ref="form"
          label-width="120px"
          v-model="AttributeGroupItemModel"
          :model="AttributeGroupItemModel"
          :rules="AttributeGroupRules"
          style="margin-top: 15px"
        >
          <el-form-item
            :label="tools.GetLanguageValue('web.属性组')"
            prop="GroupId"
          >
            <el-select
              v-model="AttributeGroupItemModel.GroupId"
              @change="attrGroupItemChange"
            >
              <el-option
                v-for="item in attributeOptions"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
                placeholder="Please select"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="tools.GetLanguageValue('web.项名称')"
            prop="Name"
          >
            <el-input v-model="AttributeGroupItemModel.Name"></el-input>
          </el-form-item>
          <el-form-item :label="tools.GetLanguageValue('web.备注')" prop="Desc">
            <el-input v-model="AttributeGroupItemModel.Desc"></el-input>
          </el-form-item>
        </el-form>

        <template #footer>
          <span class="dialog-footer">
            <el-button
              @click="AttributeGroupItemWindow = !AttributeGroupItemWindow"
            >
              {{ tools.GetLanguageValue("web.关闭") }}</el-button
            >
            <el-button @click="InsertAttributeGroupItem">
              {{ tools.GetLanguageValue("web.确定") }}</el-button
            >
          </span>
        </template>
      </el-dialog>


      
    <el-dialog
      v-model="AdminPasswordVisible"
      :title="tools.GetLanguageValue('web.登录')"
      width="500"
      :before-close="handleClose"
    >
      <div style="margin: 5px">
        <el-input
          v-model="AdminPassword"
          type="password"
          :placeholder="tools.GetLanguageValue('web.请输入密码')"
        ></el-input>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">
            {{ tools.GetLanguageValue('web.关闭') }}
          </el-button>
          <el-button type="primary" @click="VerificationAdminPassword">
             {{ tools.GetLanguageValue('web.确定') }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    </div>
  </navigation>
</template>
<script setup>
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";

import navigation from "../../../components/Modules/Navigation.vue";
import { ElMessageBox, MessageBox, ElMessage } from "element-plus";
import { ElLoading } from "element-plus";
import Collapse from "../../../components/collapse";
import BoxGridPassword from "../../../components/BoxGridPassword.vue";

import {
  ref,
  onBeforeMount,
  onUnmounted,
  onMounted,
  reactive,
  nextTick,
} from "vue";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import {
  Search,
  Plus,
  ChatLineRound,
  Tickets,
  Hide,
  CircleCloseFilled,
  Coin,
  Calendar,
  Management,
  CirclePlusFilled,
  CircleCheck,
  Goods,
  Close,
  Apple,
  Delete,
  CircleClose,
  Lock,
  DeleteFilled,
  LocationFilled,
  CirclePlus,
  Cpu,
  FolderAdd,
  Document,
  Warning,
  Refresh,
  Avatar,
  Postcard,
  CreditCard,
  Position,
  Location,
  Star,
  InfoFilled,
  PhoneFilled,
  Message,
} from "@element-plus/icons-vue";
 
const route = useRoute();
const router = useRouter();

const navigations = ref();
const cardContentHeight = ref(100);
const ShowAddWindow = ref(false);

const clientWidth = document.body.clientWidth;
const clientHeight = ref(document.documentElement.clientHeight);
const UserSeletValueWindow = ref(false); // 用户选择框控制变量
const cardItemHeight = ref(document.documentElement.clientHeight - 120); // 卡片模块高度
const weixiuItemsHeight = ref(document.documentElement.clientHeight - 1);

// 监听页面大小变化事件
const getWindowInfo = () => {
  const windowInfo = {
    width: window.innerWidth,
    hight: window.innerHeight,
  };
  clientHeight.value = document.documentElement.clientHeight;
  navigations.value.onresize();
  cardItemHeight.value = windowInfo.hight - 120;
  weixiuItemsHeight.value =
    clientHeight.value - cardContentHeight.value.clientHeight - 260;
};
window.addEventListener("resize", getWindowInfo);

const AttributeGroupItemWindow = ref(false);
const AttributeGroupItemModel = ref({});

const OpenAttachmentWindow = ref(false);
const AttachmentOpt = ref({});

const ProductServerOpt = ref({
  Name: "",
  Code: "",
  IncludeTax: "",
  Price: 0,
  OldPrice: 0,
  Sort: 0,
  ProductType: "",
  MnemonicCode: "",
  Cost: "",
});
const ProductServiceWindow = ref(false);

let repairId = ref(""); // 维修单id
let repairItemId = ref(""); // 定位用的维修单行id

// const clientId = ref("")// 客户id
const nextSelectTxt = ref(">"); // 下一个选中的显示值
const nextSelectValue = ref(2); // 下一个选中的值
const userListloading = ref(false); // 查询用户loading
const userListoptions = ref([]); // 查询用户选项列表
const userSeletvalue = ref({}); // 从查询中选择的用户
// let clientId = ref("")
const name = ref("用户:");
const show = ref(true);
const loadingForRowSave = ref(false); // 列表保存loading
const loadingForRowSaveText = ref("Saving...");
// const repairItemName = ref("") // 输入的名字
// const repairItemNum = ref("数量") // 输入的数量
// const repairItemPrice = ref("单价") // 输入的单价
// const repairItemBudget = ref("预算") // 输入的预算
// const repairItemDeposit = ref("定金") // 输入的定金
const searchInputAttachement = ref("");
const searchInputServerAndQues = ref("");
const repairItemDataInfoForMobile = ref([]); // 手机的维修项目
const repairItemDataInfo = ref([]); // 维修的项目
const repairItem = ref({
  Name: undefined, // 维修项名称
  Number: "", // 数量
  Price: "", // 单价
  TotalPrice: "", // 总价
  RepairId: undefined, // 维修单Id
  FrontMobileId: ref(""), // 前端生成的手机id
}); // 维修项目最新的值,每次点击的时候都改变这个值，要点添加的时候就添加它

const mobileDataInfoDialogName = ref("新增");
const mobileDataInfoDialogId = ref("");
const repairMobileDataWindow = ref(false);
const repairMobileDataInfo = ref([]); // 维修的手机信息列表
const repairMobileInfo = ref({
  Id: undefined,
  FrontMobileId: ref(""), // 前端自定义的Id用于前端关联维修项
  RepairId: undefined,
  Brand: undefined,
  Model: undefined,
  BrandAndModel: ref(""),
  RepairProblems: ref(""),
  ModelProperties: ref([]),
  ImelNumber: ref(""),
  PasswordType: ref(0),
  Repairstatus: ref(1),
  Password: ref(""),
  WarrantyStartTime: new Date(),
  WarrantyEndTime: new Date(),
  RepairStartTime: new Date(),
  RepairEndTime: new Date(),
  Comment: ref("备注"),
}); // 维修的手机信息
const repairItems = ref([]);
const totalMoblieAllPrice = ref(0); // 根据手机统计的价格
const totalAllPrice = ref(0); // 统计的价格
const userForm = ref({
  Id: undefined,
}); // 用户数据
const repairForm = ref({
  Id: undefined,
  Repairstatus: ref(1),
}); // 维修单数据

const docTypeOptions = ref([]); // 证件类型
const clientLevelOptions = ref([]); // 客户级别
const repairstatusOptions = ref([]); // 维修状态数据
const passwordTypeOptionsMap = ref([
  {
    value: 0,
    label: "none",
  },
  {
    value: 1,
    label: "password",
  },
  {
    value: 2,
    label: "pattern",
  },
]); // 密码类型定义
const passwordTypeOptions = reactive(passwordTypeOptionsMap.value); // 密码类型
const serverQuestionOptions = ref([]); // 服务和问题数据
const accessoriesOptions = ref([]); // 配件数据
const brandOptions = ref([]); // 品牌数据
const modelOptions = ref([]); // 型号
const attributeOptions = ref([]); // 属性列表

const iframeRefs = ref([]);
const setItemRef = (el, key) => {
  if (el) {
    iframeRefs.value.push(el);
  }
};

// 定义onMounted函数
onMounted(() => {
  repairId.value = route.query.id;
  repairItemId.value = route.query.itemId;

  clientHeight.value = document.documentElement.clientHeight;
  navigations.value.onresize();
  cardItemHeight.value = clientHeight.value - 120;
  weixiuItemsHeight.value =
    clientHeight.value - cardContentHeight.value.clientHeight - 260;
 
    CheckAdminPassword();
});

const  LoadDetail = () => {
  getProductBrands();
  getProductModelsByBrands(repairId.value);

  getRepairInfo(); 
};


const AdminPasswordVisible = ref(false);
const AdminPassword = ref("");
const AdminLogin = () => {
  AdminPasswordVisible.value = true;
};
//验证密码获取token
const VerificationAdminPassword = () => {
  axios.apiMethod(
    "/stores/System/VerificationAdminPassword",
    "post",
    { Password: AdminPassword.value },
    (result) => {
      if (result.Data.IsSuccess) {
        sessionStorage.setItem("VerificationAdminPassword", result.Data.Token);
        //加载数据
       LoadDetail();
        AdminPasswordVisible.value = false;
      } else {
        ElMessage({
          message: tools.GetLanguageValue("web.密码错误"),
          type: "error",
        });
      }
    }
  );
};

//检查admin密码，检查完成后才可以访问本页面
const CheckAdminPassword = () => {
  var token = sessionStorage.getItem("VerificationAdminPassword");
  var account = sessionStorage.getItem("VerificationAdminAccount");
  axios.apiMethod(
    "/stores/System/CheckAdminPassword",
    "post",
    { Password: token ,Account: account},
    (result) => {
      if (!result.Data.IsSuccess) {
        AdminPasswordVisible.value = true;
      } else {
        //加载数据
        LoadDetail();
      }
    }
  );
};



//打印的操作
const UpdatePrintInfo = () => {
  axios.apiMethod(
    "/stores/Repair/UpdatePrintInfo",
    "post",
    repairForm.value,
    (result) => {
      getRepairInfo();
      printWarranty();
    }
  );
};

 
//配置信息
const settingModel = ref({
  DotPrintCounterfoil: localStorage.getItem("DotPrintCounterfoil") == "yes",
  DotPrintDrawer: localStorage.getItem("DotPrintDrawer") == "yes",
});

//设置打印存根
const ChangeDotPrintCounterfoil = (e) => {
  settingModel.DotPrintCounterfoil = e;
  localStorage.setItem("DotPrintCounterfoil", e ? "yes" : "no");
};
//设置打印操作人
const ChangeDotPrintDrawer = (e) => {
  settingModel.DotPrintDrawer = e;
  localStorage.setItem("DotPrintDrawer", e ? "yes" : "no");
};

const UploadPictureImageUrl = ref(axios.GetUrl() + "/api/Upload/ImageFileWebp");
const headersImage = ref({
  Authorization: localStorage.getItem("Authentication_Header"),
});

const ComImageUrl = ref();
const ComImageFormet = ref();

const UploadSuccess = (response, file, fileList) => {
  if (response.Code == 200) {
    ComImageUrl.value = response.Data.FileName;
    ComImageFormet.value = response.Data.ImageFormet;
    AttachmentOpt.value.ImagesFormat = response.Data.ImageFormet;
    AttachmentOpt.value.Images = response.Data.FileName;
  }
};

onBeforeRouteLeave((to, from, next) => {
  const isEdit = verificationSave();

  if (!isEdit) {
    ElMessageBox.confirm(
      tools.GetLanguageValue("web.您有改变未保存") +
        tools.GetLanguageValue("web.继续") +
        tools.GetLanguageValue("web.退出") +
        tools.GetLanguageValue("web.不会保存您的更改"),
      "Warning",
      {
        confirmButtonText: tools.GetLanguageValue("web.退出"),
        cancelButtonText: tools.GetLanguageValue("web.取消"),
        type: "warning",
        title: tools.GetLanguageValue("web.提示"),
      }
    )
      .then(() => {
        next();
      })
      .catch(() => {
        next(false);
      });

    return;
  }
  next();
});

// 定义挂载前
onBeforeMount(() => {
  GetClientDocTypeEnum(); // 客户证件类型
  ClientLevelEnum(); // 客户级别
  getRepairstatusEnum(); // 维修状态选项
  getserverQuestions(); // 服务和问题数据
  getAccessories(); // 配件数据

  getAttributeGroupItems(); // 属性数据
});

onUnmounted(() => {
  //移除监听事件
  window.removeEventListener("resize", getWindowInfo);
 
});

const verificationFormJson = ref("");
const verificationUserJson = ref("");

//判断是否保存
const verificationSave = () => {
  if (JSON.stringify(repairForm.value) != verificationFormJson.value) {
    return false;
  }
  if (JSON.stringify(userForm.value) != verificationUserJson.value) {
    return false;
  }
  return true;
};

// 获取品牌
const getProductBrands = () => {
  axios.apiMethod(
    "/stores/Data/GetProductBrands",
    "post",
    {
      RepairId: repairId.value,
    },
    (result) => {
      brandOptions.value = result.Data;
    }
  );
};

// 获取维修单信息
const getRepairInfo = () => {
  axios.apiMethod(
    "/stores/System/GetRepairIncludeDeleteById",
    "get",
    {
      id: repairId.value,
      repairItemId: repairItemId.value,
    },
    (result) => {
      // userForm.value.Id = result.Data.ClientId;
      repairForm.value = result.Data;
      userForm.value = result.Data.ClientInfo;

      if (result.Data.IsCreate) {
        AddNewMobileInfoData();
      }
      verificationFormJson.value = JSON.stringify(repairForm.value);
      verificationUserJson.value = JSON.stringify(userForm.value);
      // getRepairMobileDataInfo();
    }
  );
};

//新增一个
const AddNewMobileInfoData = () => {
  repairForm.value.RepairMobileInfos.unshift({
    RepairItems: [],
    AddCustomModel: {
      Number: 1,
    },
    ActiveTab: "server",
    Repairstatus: 0,
    ProductBrands: brandOptions.value,
    ProductModels: modelOptions.value,
  });
};

 
//切换品牌
const brandSelectChange = (item) => {
  brandOptions.value.forEach((e) => {
    if (e.Id == item.Brand) {
      item.ProductModels = e.ProductModels;
    }
  });
};

const ProductModels = ref([]);

// 获取所有型号
const getProductModelsByBrands = (repairId) => {
  axios.apiMethod(
    "/stores/Data/GetProductModelsByBarndId",
    "get",
    {
      repairId: repairId,
    },
    (result) => {
      ProductModels.value = result.Data;
    }
  );
};
  
//-------------------------------   END  ---------------------------------------------------

// 统计总的价格

const totalPriceForMobile = () => {
  console.log("统计手机总的价格");
  return repairItemDataInfoForMobile.value.reduce(
    (totalAllPrice, item) => totalAllPrice + item.TotalPrice,
    0
  );
};
 
// 双击维修项目进行编辑
const editRepairItemData = (row, column) => {
  row[column.property + "isShow"] = true;
  nextTick(() => {
    ref[column.property] && ref[column.property].focus();
  });
};

// 新增或者修改客户
const addOrUpdatClient = (op) => {
  if (op === "add") {
    userForm.value.Id = undefined;

    // 新增
  } else if (op === "update") {
    // 修改-什么都不用做
  }

  axios.apiMethod(
    "/stores/Client/AddOrEditClient",
    "post",
    userForm.value,
    (result) => {
      if (result.Code === 200) {
        userForm.value = result.Data;
        repairForm.value.ClientId = result.Data.Id; // 维修单的客户Id填成当前这个客户的
        if (op === "add") {
          repairForm.value.RepairNo = "";
          repairForm.value.Id = undefined;
        }
        ElMessageBox({
          title: "提示",
          message: "操作成功",
        });
      } else {
        ElMessageBox({
          message: result.FullMsg,
          type: "error",
        });
      }
    }
  );
};
 
  

const activeTab = "server";

// Guid判断
const isGuid = (str) => {
  const guidPattern =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return (
    guidPattern.test(str) && str !== "00000000-0000-0000-0000-000000000000"
  );
};

// 获取维修项目信息
const getRepairItemDataInfo = () => {
  console.log("获取维修项目信息.....");
  axios.apiMethod(
    "/stores/RepairItem/GetGetRepairItemByRepairId",
    "get",
    {
      repairId: repairForm.value.Id,
    },
    (result) => {
      console.log("获取维修项目信息end.....");
      console.log(result);
      repairItemDataInfo.value = result.Data;

      totalAllPrice.value = totalPrice();
      if (result.Data === null) {
      }
    }
  );
};

// 获取维修项目信息
const GetRepairItemsByRepairMobileInfoId = (id) => {
  console.log("获取维修项目信息.....");
  axios.apiMethod(
    "/stores/RepairItem/GetRepairItemsByRepairMobileInfoId",
    "get",
    {
      repairMobileInfoId: id,
    },
    (result) => {
      console.log("获取维修项目信息end.....");
      console.log(result);
      repairItemDataInfoForMobile.value = result.Data;
      totalMoblieAllPrice.value = totalPriceForMobile();
      console.log(repairItemDataInfoForMobile.value);
      console.log("you mie");
      if (result.Data === null) {
      }
    }
  );
};

// 获取客户信息
const getClientInfo = () => {
  console.log("获取客户信息.....");
  console.log(userForm.value.Id);
  axios.apiMethod(
    "/stores/Client/GetClientById",
    "get",
    {
      Id: userForm.value.Id,
    },
    (result) => {
      console.log("获取客户信息end.....");
      console.log(result);
      if (result.Data === null) {
        // 说明没有用户信息
        ElMessageBox({
          title: "提示",
          message: "维修单所关联的客户信息不存在",
        });
      }
      userForm.value = result.Data;
    }
  );
};

// 获取客户证件类型
const GetClientDocTypeEnum = () => {
  axios.apiMethod(
    "/sys/SystemData/GetClientDocTypeEnum",
    "get",
    null,
    (result) => {
      docTypeOptions.value = result.Data;
    }
  );
};
// 获取客户级别
const ClientLevelEnum = () => {
  axios.apiMethod(
    "/sys/SystemData/GetClientLevelEnum",
    "get",
    null,
    (result) => {
      clientLevelOptions.value = result.Data;
    }
  );
};
// 状态下拉列表初始化
const getRepairstatusEnum = () => {
  axios.apiMethod(
    "/sys/SystemData/GetRepairstatusEunm",
    "get",
    null,
    (result) => {
      console.log(result.Data);
      repairstatusOptions.value = result.Data;
      console.log("状态下拉加载完成");
    }
  );
};

// 获取服务和问题
const getserverQuestions = () => {
  console.log("获取服务和问题");
  axios.apiMethod(
    "/stores/Data/GetProductServer",
    "post",
    {
      ScreenValue: searchInputServerAndQues.value,
    },
    (result) => {
      console.log("获取服务和问题");
      console.log(result.Data);
      serverQuestionOptions.value = result.Data;
    }
  );
};
// 查询配件
const getAccessories = () => {
  console.log("获取配件列表");
  axios.apiMethod(
    "/stores/Data/GetAttachment",
    "post",
    {
      ScreenValue: searchInputAttachement.value,
    },
    (result) => {
      console.log("获取配件列表");
      console.log(result.Data);
      accessoriesOptions.value = result.Data;
    }
  );
}; // 查询品牌

// 型号变更
const modelChange = () => {
  // const matchedModelOption = modelOptions.value.find(
  //   (option) => option.Id === repairMobileInfo.value.Model
  // );
  // const matcheModelName = matchedModelOption ? matchedModelOption.Name : "";
  // const matchedOption = brandOptions.value.find(
  //   (option) => option.Id === repairMobileInfo.value.Brand
  // );
  // const matcheBrandName = matchedOption ? matchedOption.Name : "";
  // if (matcheModelName !== "unknow" && matcheModelName !== "") {
  //   repairMobileInfo.value.BrandAndModel =
  //     matcheBrandName + "/" + matcheModelName;
  // } else {
  //   repairMobileInfo.value.BrandAndModel = matcheBrandName;
  // }
};
// 品牌变更
const brandChange = () => {
  // repairMobileInfo.value.Model = undefined;
  // const matchedOption = brandOptions.value.find(
  //   (option) => option.Id === repairMobileInfo.value.Brand
  // );
  // const matcheBrandName = matchedOption ? matchedOption.Name : "";
  // if (matcheBrandName !== "unknow" && matcheBrandName !== "") {
  //   repairMobileInfo.value.BrandAndModel = matcheBrandName;
  // } else {
  //   repairMobileInfo.value.BrandAndModel = "";
  // }
  // console.log(repairMobileInfo.value.BrandAndModel);
  // // repairForm.value.Model=undefined
};
// 获取要选择的属性
const getAttributeGroupItems = () => {
  console.log("获取属性列表");
  axios.apiMethod(
    "/stores/Data/GetAttributeGroupOrItemAll",
    "post",
    {},
    (result) => {
      console.log("获取属性列表");
      console.log(result.Data);
      attributeOptions.value = result.Data;
      console.log(attributeOptions.value);
    }
  );
};

// 远程查询用户
const remoteMethod = (query) => {
  console.log("输入的查询");
  console.log(query);
  if (query) {
    userListloading.value = true;
    axios.apiMethod(
      "/stores/Client/GetClientByAnyWords?anyWords=" + query,
      "get",
      {},
      (result) => {
        if (result.Code === 200) {
          console.log(result);
          userListoptions.value = result.Data;
          userListloading.value = false;
        } else {
          ElMessageBox({
            message: result.FullMsg,
            type: "error",
          });
          userListloading.value = false;
        }
      }
    );
  } else {
    userListoptions.value = [];
  }
};  

const AddProductService = () => {
  axios.apiMethod(
    "/stores/GoodsManage/AddProductService",
    "post",
    ProductServerOpt.value,
    (result) => {
      if (result.Data.IsSuccess) {
        getserverQuestions();
        ProductServiceWindow.value = false;
      } else {
        ElMessageBox({
          message: result.FullMsg,
          type: "error",
        });
      }
    }
  );
};   
const initMobileDataInfo = () => {
  nextSelectValue.value = 2;
  nextSelectTxt.value = ">";
  repairItemDataInfoForMobile.value = [];
  totalMoblieAllPrice.value = 0;
  totalAllPrice.value = 0;
  clearMobileInfoData();
  clearRepairItemData();
  repairMobileInfo.value.FrontMobileId = generateGuid();
};
 
 
 
// 添加配件
const AddAttachment = () => {
  axios.apiMethod(
    "/stores/GoodsManage/AddAttachment",
    "post",
    AttachmentOpt.value,
    (result) => {
      if (result.Data.IsSuccess) {
        getAccessories();
        OpenAttachmentWindow.value = false;
      } else {
        ElMessageBox({
          message: result.FullMsg,
          type: "error",
        });
      }
    }
  );
};
 
// 新增属性时，选择属性组
const attrGroupItemChange = (current) => {
  // console.log(current)
  // console.log(AttributeGroupItemModel.value)
  // AttributeGroupItemModel.value.GroupId=
};
// 插入新属性
const InsertAttributeGroupItem = () => {
  axios.apiMethod(
    "/stores/GoodsManage/InsertAttributeList",
    "post",
    AttributeGroupItemModel.value,
    (result) => {
      if (result.Code === 200) {
        AttributeGroupItemWindow.value = false;
        getAttributeGroupItems();
      } else {
        ElMessageBox({
          message: result.FullMsg,
          type: "error",
        });
      }
    }
  );
};
const printXiaoPiao = () => {
  if (repairForm.value.Id === undefined) {
    ElMessageBox({
      title: "提示",
      message: "未保存维修单,无法打印",
    });
    return;
  }
  // 构造参数字符串
  const queryParams = new URLSearchParams({
    id: repairForm.value.Id,
  });

  // 使用 window.location 打开新页面
  window.open(`/printXiaoPiaoEighty?${queryParams.toString()}`, "_blank");
  // router.push({ name: 'printXiaoPiaoEighty', query: { id: 'new' }})
}; 

const printWarranty = () => {
  if (repairForm.value.Id === undefined) {
    ElMessageBox({
      title: "提示",
      message: "未保存维修单,无法打印",
    });
    return;
  }
  // 构造参数字符串
  const queryParams = new URLSearchParams({
    id: repairForm.value.Id,
  });

  // 使用 window.location 打开新页面
  window.open(`/printWarranty?${queryParams.toString()}`, "_blank");
  // router.push({ name: 'printWarranty', query: { id: 'new' }})
};  
  
// 清空维修手机信息
const clearMobileInfoData = () => {
  repairMobileInfo.value = {
    Id: undefined,
    FrontMobileId: ref(""), // 前端自定义的Id用于前端关联维修项
    RepairId: undefined,
    Brand: undefined,
    Model: undefined,
    BrandAndModel: ref(""),
    RepairProblems: ref(""),
    ModelProperties: ref([]),
    ImelNumber: ref(""),
    PasswordType: ref(0),
    Repairstatus: ref(1),
    Password: ref(""),
    WarrantyStartTime: new Date().toISOString(),
    WarrantyEndTime: new Date().toISOString(),
    RepairStartTime: new Date().toISOString(),
    RepairEndTime: new Date().toISOString(),
    Comment: ref("备注"),
    ActiveTab: "server",
  };
  console.log("wanle ");
};
const clearRepairItemData = () => {
  repairItem.value = {
    Name: undefined, // 维修项名称
    Number: 0, // 数量
    Price: 0.0, // 单价
    TotalPrice: 0.0, // 总价
    RepairId: undefined, // 维修单Id
    FrontMobileId: ref(""), // 前端生成的手机id
  };
};
// 生成guid
const generateGuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0;
    var v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
</script>
<style lang="scss">
.el-select {
  .select-trigger {
    .el-select__input {
      min-width: 5px;
    }
  }
}
.floating-save-button {
  position: fixed;
  bottom: 2px;
  right: 20px;
  z-index: 1000;
}
.input {
  margin: 5px 0px;
}

.userInfoInput {
  max-width: 23%;
}
</style>
